// 组件
import importImg from '@/components/importImg.vue'
// 接口
import * as shopsApi from '@/services/shops.js'
import * as shopItemsApi from '@/services/shop-items.js'
import * as shopItemStocksApi from '@/services/shop-item-stocks.js'
// 其他
import dataDictionary from '@/utils/data-dictionary.js'
import constant from '@/utils/constant.js'
import verifyRules from '@/utils/verify-rules/index.js'
import loading from '@/utils/loading.json'
import Utils from '@/utils/Utils.js'

export default {
  components: {
    importImg
  },
  data () {
    return {
      app_name: process.env.VUE_APP_NAME,
      constant,
      dataDictionary,
      verifyRules: verifyRules(this),
      urlParams: null,
      shopList: [],
      shopItemList: [],
      mainForm: {
        shopSelect: {
          disabled: true,
          loading: false
        },
        shopItemSelect: {
          disabled: false,
          loading: false
        },
        cover: [],
        data: {
          online: true,
          shop_id: null,
          shop_item_id: null,
          name: null,
          name_en: null,
          sku_id: null,
          price_f: null,
          cost_price_f: null,
          line_price_f: null,
          host_commission_percent: null,
          direct_delivery_host_commission: null,
          remote_image_url: null,
          saling_stock: null,
          warning_stock: null,
          inventory_quantity: 1,
          zone: null,
          section: null,
          bin: null
        }
      }
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    if (this.app_name === 'combine') {
      Object.assign(this.mainForm.data, {
        zone: null,
        section: null,
        bin: null
      })
    }
    const _this = this
    shopsApi.getShopMap().then(res => {
      _this.shopList = res.data.shops
    }).catch(err => err)
    _this.setGlobalLoading(true)
    shopItemStocksApi.getIdPreview(_this.urlParams.id).then(res => {
      const resData = res.data.shop_item_stock
      _this.mainForm.shopItemSelect.loading = true
      shopItemsApi.getShopItemByShopList({
        shop_id: resData.shop_id
      }).then(_res => {
        _this.setGlobalLoading(false)
        _this.mainForm.shopItemSelect.loading = false
        _this.shopItemList = _res.data.item_type_list
        Object.assign(_this.mainForm.data, resData)
        this.handleChange(this.mainForm.data.name, 'name')
        this.handleChange(this.mainForm.data.name_en, 'name_en')
        _this.mainForm.data.remote_image_url = resData.image_url
        _this.mainForm.cover = [{ id: Date.now(), image_url: resData.image_url }]
      }).catch(() => {
        _this.setGlobalLoading(false)
        _this.mainForm.shopItemSelect.loading = false
      })
    }).catch(() => _this.setGlobalLoading(false))
  },
  methods: {
    handleChange (data, prop) {
      if (prop === 'name' && !Utils.isNull(data)) {
        this.verifyRules.goodsManagerVariationsAdd.mainForm.name_en[0].required = false
      }
      if ((prop === 'name' || prop === 'name_en') && Utils.isNull(this.mainForm.data.name) && Utils.isNull(this.mainForm.data.name_en)) {
        this.verifyRules.goodsManagerVariationsAdd.mainForm.name_en[0].required = true
        this.verifyRules.goodsManagerVariationsAdd.mainForm.name[0].required = true
      }
      if (prop === 'name_en' && !Utils.isNull(data)) {
        this.verifyRules.goodsManagerVariationsAdd.mainForm.name[0].required = false
      }
      this.$nextTick(() => {
        this.$refs.mainForm.clearValidate()
      })
    },
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    handleMainFormShopSelectChange (val) {
      const _this = this
      _this.shopItemList = []
      _this.mainForm.data.shop_item_id = null
      _this.mainForm.shopItemSelect.loading = true
      shopItemsApi.getShopItemByShopList({
        shop_id: val
      }).then(res => {
        _this.mainForm.shopItemSelect.loading = false
        _this.shopItemList = res.data.item_type_list
      }).catch(() => {
        _this.mainForm.shopItemSelect.loading = false
      })
    },
    handleMainFormImgChange (fileData) {
      const _this = this
      console.log('>>>>>>>>>', fileData)
      if (fileData && fileData.length > 0 && !fileData[0]._destroy) {
        _this.mainForm.data.remote_image_url = fileData[0].url
        _this.mainForm.data.should_remove_image = false
      } else {
        _this.mainForm.data.remote_image_url = null
        _this.mainForm.data.should_remove_image = true
      }
      console.log('>>>>>>>>>', _this.mainForm.data.remote_image_url)
    },
    handleMainFormCancelClick () {
      const _this = this
      _this.$router.go(-1)
    },
    handleMainFormSubmitClick () {
      const _this = this
      _this.$refs.mainForm.validate((valid) => {
        if (valid) {
          let requestParams = {}
          Object.assign(requestParams, _this.mainForm.data)
          requestParams = {
            shop_item_stock: {
              ...requestParams
            }
          }
          delete requestParams.shop_item_stock.image_url
          _this.setGlobalLoading(true)
          shopItemStocksApi.putShopItemStocks(_this.urlParams.id, requestParams).then(res => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            setTimeout(() => {
              _this.$router.push({
                name: 'goodsManagerVariationsList'
              })
            }, constant.ROUTER_CHANGE_INTERVAL)
          }).catch(() => _this.setGlobalLoading(false))
        } else {
          this.$message.error(this.$t('message.formIsRequire'))
          return false
        }
      })
    }
  }
}
