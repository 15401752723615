import request from '@/utils/request'

export const getItemTypeList = () => {
  return request({
    method: 'GET',
    url: '/cms/shop_items/item_type_list'
  })
}

export const getShopItemByShopList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_items/shop_item_by_shop_list',
    params
  })
}

export const postShopItems = (data) => {
  return request({
    method: 'POST',
    url: '/cms/shop_items',
    data
  })
}

export const getIdPreview = (id) => {
  return request({
    method: 'get',
    url: `/cms/shop_items/${id}/preview`
  })
}

export const putShopItems = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/shop_items/${id}`,
    data
  })
}

export const getShopItemsItemables = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_items/itemables',
    params
  })
}

export const getItemTypeListByShop = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_items/item_type_list_by_shop',
    params
  })
}
