/**
 * 商品型号接口
 */

import request from '@/utils/request'

export const postShopItemStocks = (data) => {
  return request({
    method: 'POST',
    url: '/cms/shop_item_stocks',
    data
  })
}

export const putShopItemStocks = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/shop_item_stocks/${id}`,
    data
  })
}

export const getIdPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_item_stocks/${id}/preview`
  })
}

export const postImport = (data) => {
  return request({
    method: 'POST',
    url: '/cms/shop_item_stocks/import',
    data
  })
}

export const getExport = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_item_stocks/export',
    params
  })
}

export const postImportWholesalePrice = (data) => {
  return request({
    method: 'POST',
    url: '/cms/shop_item_stocks/import_tier_prices',
    data
  })
}
